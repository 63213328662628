// extracted by mini-css-extract-plugin
export var benefit = "Partnering-module--benefit--438be";
export var benefitCont = "Partnering-module--benefitCont--cb3dc";
export var cards = "Partnering-module--cards--4743e";
export var description = "Partnering-module--description--c26d8";
export var future = "Partnering-module--future--3dc17";
export var heading = "Partnering-module--heading--f5b63";
export var ser = "Partnering-module--ser--4844b";
export var talent = "Partnering-module--talent--cd834";
export var text = "Partnering-module--text--6213c";
export var tick = "Partnering-module--tick--f1b46";