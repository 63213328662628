import React from "react"
import Row from "react-bootstrap/Row"
import { Col } from "react-bootstrap"
import { Container } from "react-bootstrap"
import * as styles from "./Partnering.module.scss"

function Partnering({ strapiData }) {
  return (
    <div className={styles.benefit}>
      <Container className="benefitCont">
        <div className={styles.future}>
          <p
            className={styles.ser}
            // dangerouslySetInnerHTML={{
            //   __html: strapiData?.subTitle2,
            // }}
          >
            Why InvoZone?
          </p>

          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{
              __html: strapiData?.title,
            }}
          ></h2>
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={`${styles.cards} gap-30`}>
            {strapiData?.cards &&
              strapiData?.cards?.map((item, index) => (
                <Col lg={6} className={`${styles.talent} gap-30`} key={index}>
                  <img
                    className={styles.tick}
                    src={
                      "https://invozone-backend.s3.us-east-1.amazonaws.com/check_04fb87f210.svg"
                    }
                    alt={item?.title}
                  />
                  <div className={styles.text}>
                    <h3>{item?.title}</h3>
                    <p>{item?.subTitle}</p>
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Partnering
