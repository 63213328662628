// extracted by mini-css-extract-plugin
export var Heading = "Banner-module--Heading--1fe13";
export var bannerCon = "Banner-module--bannerCon--a2aee";
export var bannerHeading = "Banner-module--bannerHeading--69828";
export var btn = "Banner-module--btn--ff2f0";
export var description = "Banner-module--description--1739a";
export var fintech = "Banner-module--fintech--eb19d";
export var freshprepHeading = "Banner-module--freshprepHeading--7e5bc";
export var imageAnimation = "Banner-module--imageAnimation--be96f";
export var portfolioAppWorkBanner = "Banner-module--portfolioAppWorkBanner--8aa2f";
export var subTitle = "Banner-module--subTitle--ad299";
export var topToBottomBounce = "Banner-module--topToBottomBounce--f8396";