// extracted by mini-css-extract-plugin
export var Android = "Services-module--Android--cad68";
export var arrowDiv = "Services-module--arrowDiv--fcced";
export var arrowImg = "Services-module--arrowImg--94476";
export var boxContent = "Services-module--boxContent--a6701";
export var btn = "Services-module--btn--71a0b";
export var cards = "Services-module--cards--1e90f";
export var cardsContent = "Services-module--cardsContent--a9203";
export var cardsZ = "Services-module--cardsZ--62d6a";
export var djangoArrowImg = "Services-module--djangoArrowImg--a6f2c";
export var estabBtn = "Services-module--estabBtn--1a3f7";
export var explorrBtn = "Services-module--explorrBtn--5f699";
export var finImg = "Services-module--finImg--380b9";
export var heading = "Services-module--heading--c6baf";
export var processSteps = "Services-module--processSteps--9b276";
export var product1 = "Services-module--product1--b206c";
export var ser = "Services-module--ser--50a83";
export var soft = "Services-module--soft--44f26";
export var tabImg = "Services-module--tabImg--cdd08";
export var tabbingCards = "Services-module--tabbingCards--39707";